import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
} from '@dnd-kit/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Layout/Header';
import SearchBar from '../../components/Portfolio/SearchBar';
import {
  Container,
  ContentWrapper,
  Wrapper,
} from '../../components/Portfolio/style';
import PortfolioSidebar from '../../components/Sidebar/PortfolioSidebar';
import { useUpdateRegionMutation } from '../../redux/api/regionApi';
import { useAppSelector } from '../../redux/hooks';

const PortfolioPage = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const [updateRegion, { error, reset }] = useUpdateRegionMutation();

  useEffect(() => {
    if (user && user.role === 'free') {
      navigate('/');
    }
  }, [navigate, user]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'update-region-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const overFolderId = over?.data?.current?.folderId;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const activeFolderId = active?.data?.current?.folderId;

      if (!overFolderId || activeFolderId === overFolderId) return;

      const data = await updateRegion({
        id: String(active?.data?.current?.regionId),
        body: {
          folderId:
            String(overFolderId) === 'unassigned' ? null : String(overFolderId),
        },
      });

      if (!('error' in data)) {
        toast.success(
          t('Region updated successfully', {
            toastId: 'region-updated-successfully',
            autoClose: 3000,
          })
        );
        reset();
        window.postMessage('regionUpdated', '*');
      }
    },
    [reset, t, updateRegion]
  );

  return (
    <Wrapper>
      <Header />
      <Container>
        <DndContext onDragEnd={handleDragEnd} sensors={[mouseSensor]}>
          <PortfolioSidebar />
          <ContentWrapper>
            <SearchBar
              inputValue={searchValue}
              setInputValue={setSearchValue}
            />
            <Outlet />
          </ContentWrapper>
        </DndContext>
      </Container>
    </Wrapper>
  );
};

export default PortfolioPage;
