import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateUserMutation } from '../../../redux/api/userApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AreaUnitType } from '../../../types/Geo';
import { ISettings } from '../../../types/User';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Select, { Props as SelectProps } from '../../Common/Select';
import Spacer from '../../Common/Spacer';
import { SelectLabel, SelectWrapper } from '../AccountModal/Settings/style';
import { ButtonWrapper, Subtitle, Title } from '../ModalCommon.style';

const UserSettingsModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.userState);
  const [updateUser, { isLoading, isSuccess, error, reset }] =
    useUpdateUserMutation();
  const [unit, setUnit] = useState<AreaUnitType>(
    user?.settings.unit || AreaUnitType.hectare
  );
  const [language, setLanguage] = useState(user?.settings.language || 'en-US');
  const unitOptions: SelectProps['options'] = useMemo(
    () => [
      {
        value: AreaUnitType.squareKilometre,
        label: t('Square Kilometers'),
      },
      {
        value: AreaUnitType.hectare,
        label: t('Hectares'),
      },
      {
        value: AreaUnitType.acre,
        label: t('Acre'),
      },
      {
        value: AreaUnitType.squareMile,
        label: t('Square Miles'),
      },
    ],
    [t]
  );

  const languageOptions: SelectProps['options'] = useMemo(
    () => [
      {
        value: 'en-US',
        label: t('English'),
      },
    ],
    [t]
  );

  const handleChangeLang = useCallback((val: string) => {
    setLanguage(val);
  }, []);

  const handleChangeUnit = useCallback((val: AreaUnitType) => {
    setUnit(val);
  }, []);

  const handleSubmit = useCallback(async () => {
    const settings: ISettings = {
      ...user?.settings,
      language,
      unit,
    };
    await updateUser({
      settings,
    });
  }, [language, unit, updateUser, user?.settings]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Settings saved successfully'), {
        autoClose: 3000,
        toastId: 'user-settings-modal-toast',
      });
      dispatch(closeModal());
    }
  }, [dispatch, isSuccess, t]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'user-settings-modal-error',
        autoClose: 3000,
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      modalType="userSettings"
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="user-settings-modal"
    >
      <Title data-test-id="user-settings-modal-title">
        {t('User Settings')}
      </Title>
      <Subtitle data-test-id="user-settings-modal-subtitle">
        {t('Quickly change your user settings here')}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <SelectWrapper>
        <SelectLabel>{t('Data units')}</SelectLabel>
        <Select
          value={unit}
          onChange={handleChangeUnit}
          options={unitOptions}
          dataTestId="user-settings-modal-unit-select"
          additionalSx={{
            width: '400px',
          }}
          additionalPaperSx={{
            ul: {
              padding: 0,
            },
          }}
          autoWidth={false}
        />
      </SelectWrapper>
      <Spacer size="15px 0 0" />
      <SelectWrapper>
        <SelectLabel>{t('Application language')}</SelectLabel>
        <Select
          value={language}
          onChange={handleChangeLang}
          options={languageOptions}
          dataTestId="user-settings-modal-language-select"
          additionalSx={{
            width: '400px',
          }}
          additionalPaperSx={{
            ul: {
              padding: 0,
            },
          }}
          autoWidth={false}
        />
      </SelectWrapper>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="user-settings-modal-cancel-button"
          additionalSx={{
            justifyContent: 'flex-start',
            paddingLeft: 0,
            fontSize: '14px',
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          dataTestId="user-settings-modal-save-button"
          variant="purple"
          fullWidth
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {t('Save')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default UserSettingsModal;
