import { Tab, Tabs } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { PORTFOLIO_SIDEBAR_STEPS } from '../../../constants/joyride';
import useJoyride from '../../../hooks/useJoyride';
import {
  folderApi,
  useGetUserFoldersQuery,
} from '../../../redux/api/folderApi';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../redux/hooks';
import Icon from '../../Common/Icon';
import Droppable from '../../DragAndDrop/Droppable';
import FoldersList from './FoldersList';
import {
  Divider,
  MenuItem,
  MenuOptions,
  SidebarButtonContainer,
  SidebarWrapper,
  TabsWrapper,
} from './style';

const PortfolioSidebar = () => {
  useGetUserFoldersQuery();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  useOnClickOutside(menuRef, () => setOpenMenu(false), 'mouseup');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'regionUpdated') {
        // should update the folder list now
        dispatch(folderApi.util.invalidateTags(['Folders']));
      }
    });
  }, [dispatch]);

  useJoyride(PORTFOLIO_SIDEBAR_STEPS(t));

  return (
    <SidebarWrapper>
      <h1>{t('My Portfolio')}</h1>
      <TabsWrapper>
        <Tabs
          orientation="vertical"
          value={location.pathname}
          role="navigation"
        >
          <Tab
            icon={<Icon variant="LIST" color="gray" size={24} />}
            iconPosition="start"
            label={t('Summary')}
            disableRipple
            value="/portfolio/summary"
            onClick={() => navigate('/portfolio/summary')}
            id="portfolio-sidebar-summary"
          />
          <Droppable
            id="unassigned"
            value="/portfolio/unassigned"
            folderId="unassigned"
          >
            <Tab
              icon={<Icon variant="UNASSIGNED" color="gray" size={24} />}
              iconPosition="start"
              label={t('Unassigned')}
              disableRipple
              value="/portfolio/unassigned"
              onClick={() => navigate('/portfolio/unassigned')}
              id="portfolio-sidebar-unassigned"
              sx={{ opacity: 1 }}
              classes={{
                root: location.pathname.includes('/portfolio/unassigned')
                  ? 'Mui-selected'
                  : undefined,
              }}
            />
          </Droppable>
        </Tabs>
      </TabsWrapper>
      <Divider />
      <SidebarButtonContainer
        onClick={() => setOpenMenu(true)}
        id="portfolio-sidebar-add-new"
      >
        <Icon variant="PLUS" color="silver" size={24} />
        <p>{t('Add New')}</p>
        <MenuOptions isOpen={openMenu} ref={menuRef}>
          <MenuItem onClick={() => dispatch(openModal('createFolder'))}>
            {t('New Folder')}
          </MenuItem>
          <MenuItem onClick={() => dispatch(openModal('uploadShapefile'))}>
            {t('Create Project')}
          </MenuItem>
        </MenuOptions>
      </SidebarButtonContainer>
      <FoldersList />
    </SidebarWrapper>
  );
};

export default PortfolioSidebar;
