import { StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = (columns: number) =>
  StyleSheet.create({
    title: {
      fontSize: '20px',
    },
    table: {
      width: 'auto',
    },
    tableHeader: {
      margin: 'auto',
      flexDirection: 'row',
      backgroundColor: '#fff',
      fontWeight: 'bold',
      paddingTop: '12px',
      borderBottom: '1px solid #030818',
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      // adjust column width according to the number of columns
      width: `${100 / columns}%`,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      padding: '0 12px',
      borderRight: '1px solid #030818',
    },
    tableColFirst: {
      width: `${100 / columns}%`,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 12px',
      borderRight: '1px solid #030818',
    },
    tableColLast: {
      width: `${100 / columns}%`,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      padding: '0 12px',
      borderRight: '0',
    },
    tableHeaderCell: {
      fontFamily: 'Manrope',
      fontWeight: 700,
      fontSize: '9px',
      color: '#030818',
      padding: '4px 0 0',
      display: 'flex',
      flexDirection: 'column',
    },
    tableHeaderCellSmaller: {
      fontFamily: 'Manrope',
      fontWeight: 700,
      fontSize: '8px',
      color: '#030818',
      padding: '4px 0 0',
      display: 'flex',
      flexDirection: 'column',
    },
    tableHeaderCellSubtitle: {
      fontFamily: 'Manrope',
      fontWeight: 700,
      fontSize: '7px',
      color: '#565B69',
      paddingBottom: '4px',
    },
    tableCell: {
      fontFamily: 'Manrope',
      fontSize: '11px',
      color: '#030818',
      padding: '4px 0',
    },
  });

export default styles;
