import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMenu } from '../../../hooks/useMenu';
import { useSorting } from '../../../hooks/useSorting';
import { folderApi, useGetFolderQuery } from '../../../redux/api/folderApi';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IRegionResponse } from '../../../types/API/Region';
import Droppable from '../../DragAndDrop/Droppable';
import EmptyTemplate from './EmptyTemplate';
import FloatingActionsBar from './FloatingActionsBar';
import FolderActions from './FolderActions';
import FolderHeader from './FolderHeader';
import FolderList from './FolderList';

interface Props {
  id: string;
}

const Folder = ({ id }: Props) => {
  const { t } = useTranslation();
  const { sorting, sortingDirection, handlePortfolioSorting } = useSorting(
    'createdAt',
    'desc'
  );
  const dispatch = useAppDispatch();
  useGetFolderQuery(
    {
      id,
      sorting,
      sortingDirection,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { currentFolder } = useAppSelector((state) => state.folderState);
  const { menuToShow, menuRef, handleOpenMenu, handleCloseMenu } = useMenu();
  const [selectMode, setSelectMode] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'regionUpdated') {
        // should update the folder now
        // invalidateTag Folder by id
        dispatch(folderApi.util.invalidateTags(['Folder', 'Folders']));
        setSelectedProjects([]);
        setSelectMode(false);
      }
    });
  }, [dispatch, id]);

  if (!currentFolder) {
    return <div>{t('No data found')}</div>;
  }

  const handleToggleSelectMode = () => {
    if (selectMode) {
      setSelectedProjects([]);
    }
    setSelectMode((wasSelected) => !wasSelected);
  };

  const handleSelectFolder = (folderId: IRegionResponse['id']) => {
    setSelectedProjects((projects) => {
      if (projects.includes(String(folderId))) {
        return projects.filter((project) => project !== folderId);
      }
      return [...projects, String(folderId)];
    });
  };

  return currentFolder.regions?.length === 0 ? (
    <EmptyTemplate folderId={id} />
  ) : (
    <>
      <div>
        <FolderHeader />
        {currentFolder.regions?.length !== 0 ? (
          <FolderActions
            setSelectMode={setSelectMode}
            selectMode={selectMode}
            handleToggleSelectMode={handleToggleSelectMode}
            selectedProjects={selectedProjects}
            handleSortBy={handlePortfolioSorting}
          />
        ) : null}
        <Droppable id={`folder-${id}`} folderId={id}>
          <FolderList
            currentFolder={currentFolder}
            selectMode={selectMode}
            selectedProjects={selectedProjects}
            handleSelectFolder={handleSelectFolder}
            menuToShow={menuToShow}
            menuRef={menuRef}
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
          />
        </Droppable>
      </div>
      <FloatingActionsBar
        active={selectedProjects.length > 0}
        ids={selectedProjects}
      />
    </>
  );
};

export default Folder;
