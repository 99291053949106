import { ISelectedPolygon } from '../types/API/Region';
import { IBiomassRequest } from '../types/Geo';

export const COMMON_FEATURE_BODY_TYPE = (
  selectedPolygon: ISelectedPolygon
): Omit<IBiomassRequest, 'type'> => ({
  timeStart: '1900-01-01',
  timeEnd: new Date().toISOString().split('T')[0],
  polygon: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: selectedPolygon?._geometry || selectedPolygon?.geometry,
        properties: selectedPolygon?.properties || null,
      },
    ],
  },
});
