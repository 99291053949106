import truncate from '@turf/truncate';
import {
  LngLat,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapMouseEvent,
} from 'mapbox-gl';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Layer,
  Map,
  NavigationControl,
  Source,
  useMap,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { toast, ToastOptions } from 'react-toastify';
import {
  INITIAL_MAP_VIEW_STATE,
  MAPBOX_MAP_STYLES,
  PUBLIC_SOURCE_NAME,
  PUBLIC_TILE_LAYERS,
  SOURCE_NAME,
  SPLIT_DRAWN_SOURCE_NAME_FILELD,
  SPLIT_DRAWN_SOURCE_NAME_UNFILLED,
  SPLIT_TILE_LAYERS_FILLED,
  SPLIT_TILE_LAYERS_UNFILLED,
  TILE_LAYERS,
  USER_SOURCE_NAME,
  USER_TILE_LAYERS,
} from '../../constants/map';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import { useGetSplitPolygonDataMutation } from '../../redux/api/biomassApi';
import {
  onDrawCreate,
  onDrawUpdate,
  resetDrawSliceState,
  setDrawLngLat,
  setIsDrawing,
  setNumberOfPoints,
} from '../../redux/features/draw/draw-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { PublicProject } from '../../types/API/PublicProjects';
import { IRegionResponse } from '../../types/API/Region';
import Hint from '../Common/Hint';
import DrawControl, { drawRef } from './DrawControl';
import DrawInfoComponent from './DrawInfo';
import {
  DrawInsideTileFillPaint,
  DrawInsideTileLinePaint,
  DrawOutsideTileFillPaint,
  DrawOutsideTileLinePaint,
  DrawStyles,
  FillPaint,
  LinePaint,
  MapContainer,
  PublicProjectsFillPaint,
  PublicProjectsLinePaint,
  UserFillPaint,
  UserLinePaint,
} from './style';
import StyleChange from './StyleChange';

interface MapProps {
  children?: ReactNode;
  isInteractive?: boolean;
}

const MapComponent = ({ children, isInteractive = false }: MapProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { resetPolygonData } = usePolygonContext();
  const { handleTileClick } = useMapContext();
  const { mapRoot } = useMap();

  const [mapStyle, setMapStyle] = useState(MAPBOX_MAP_STYLES.satellite);
  const [viewState, setViewState] = useState(INITIAL_MAP_VIEW_STATE);
  const hoveredFeatureId = useRef<string | null>(null);
  const hoveredPublicFeatureId = useRef<string | null>(null);
  const hoveredUserFeatureId = useRef<string | null>(null);

  const { selectedPolygon, tiles, userTiles } = useAppSelector(
    (state) => state.regionState
  );
  const { publicProjects } = useAppSelector(
    (state) => state.publicProjectsState
  );
  const { isDrawing, splitDrawnPolygon, numberOfPoints, drawnFeatures } =
    useAppSelector((state) => state.drawState);
  const { showSidebar } = useAppSelector((state) => state.uiState);
  const { sources } = useAppSelector((state) => state.mapState);

  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();

  const beforeIdOutline = useMemo(() => {
    // Order is as it goes
    // Saved projects -> Public Projects -> Predefined tiles
    if (userTiles?.length && mapRoot?.getLayer(USER_TILE_LAYERS.OUTLINE)) {
      return USER_TILE_LAYERS.OUTLINE;
    } else if (
      publicProjects.length > 0 &&
      mapRoot?.getLayer(PUBLIC_TILE_LAYERS.OUTLINE)
    ) {
      return PUBLIC_TILE_LAYERS.OUTLINE;
    }
    return undefined;
  }, [publicProjects.length, mapRoot, userTiles?.length]);

  const beforeIdFill = useMemo(() => {
    // Order is as it goes
    // Saved projects -> Public Projects -> Predefined tiles
    if (userTiles?.length && mapRoot?.getLayer(USER_TILE_LAYERS.FILLS)) {
      return USER_TILE_LAYERS.FILLS;
    } else if (
      publicProjects.length > 0 &&
      mapRoot?.getLayer(PUBLIC_TILE_LAYERS.FILLS)
    ) {
      return PUBLIC_TILE_LAYERS.FILLS;
    }
    return undefined;
  }, [publicProjects.length, mapRoot, userTiles?.length]);

  const onModeChange = useCallback(() => {
    if (isDrawing) {
      if (drawRef?.getAll().features.length === 0) {
        dispatch(setIsDrawing(false));
        toast.dismiss('hint-draw-second');
      }
    }
  }, [isDrawing, dispatch]);

  const onUserTileClick = async (e: MapMouseEvent) => {
    if (mapRoot) {
      const feature = mapRoot.queryRenderedFeatures(e.point)[0];
      // recover original geometry coordinates
      if (feature.properties && feature.properties.originalGeometry) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        feature.geometry = JSON.parse(feature.properties.originalGeometry);
      }

      const cleanedFeature: GeoJSON.Feature<GeoJSON.Polygon> | undefined =
        userTiles.find(
          (el) =>
            JSON.stringify(el.geometry) === feature.properties?.originalGeometry
        );

      if (cleanedFeature) {
        await verifySplitPolygon({
          feature: cleanedFeature,
          omitCache: false,
          withStateSet: true,
        });
        handleTileClick(cleanedFeature, USER_SOURCE_NAME);
      }
    }
  };

  const onUserTileHover = useCallback(
    (e: MapMouseEvent) => {
      const feature = mapRoot?.queryRenderedFeatures(e.point)[0];
      if (feature) {
        if (hoveredUserFeatureId.current) {
          mapRoot?.setFeatureState(
            { source: USER_SOURCE_NAME, id: hoveredUserFeatureId.current },
            { hover: false }
          );
          hoveredUserFeatureId.current = null;
        }
        mapRoot?.setFeatureState(
          { source: USER_SOURCE_NAME, id: feature.id },
          { hover: true }
        );
        if (feature.properties) {
          window.postMessage(
            {
              type: 'hover-saved-region',
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              originalGeometry: feature.properties.originalGeometry,
            },
            '*'
          );
        }
        hoveredUserFeatureId.current = String(feature.id);
      }
    },
    [hoveredUserFeatureId, mapRoot]
  );

  const onUserTileBlur = useCallback(() => {
    if (hoveredUserFeatureId.current) {
      mapRoot?.setFeatureState(
        { source: USER_SOURCE_NAME, id: hoveredUserFeatureId.current },
        { hover: false }
      );
      window.postMessage(
        { type: 'hover-saved-region', originalGeometry: null },
        '*'
      );
      hoveredUserFeatureId.current = null;
    }
  }, [mapRoot]);

  const prepareCleanedFeature = (feature: MapboxGeoJSONFeature) => {
    const newFeature = { ...feature };
    // recover original geometry coordinates
    if (feature.properties && feature.properties.originalGeometry) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
      newFeature.geometry = JSON.parse(feature.properties.originalGeometry);
    }

    return {
      type: feature.type,
      geometry: newFeature.geometry as GeoJSON.Polygon,
      properties: feature.properties,
      id: feature.properties?.name as string, // Since we don't have an ID, we are using the tile's name
    };
  };
  /**
   * Open tile data on click
   */
  const onTileClick = (e: MapMouseEvent) => {
    if (mapRoot) {
      const feature = mapRoot.queryRenderedFeatures(e.point)[0];
      const cleanedFeature = prepareCleanedFeature(feature);

      if (cleanedFeature) {
        dispatch(resetDrawSliceState());
        handleTileClick(cleanedFeature, SOURCE_NAME, feature);
      }
    }
  };

  const onPublicClick = async (e: MapMouseEvent) => {
    if (mapRoot) {
      const feature = mapRoot.queryRenderedFeatures(e.point)[0];
      const cleanedFeature = prepareCleanedFeature(feature);

      if (cleanedFeature) {
        await verifySplitPolygon({
          feature: cleanedFeature,
          omitCache: false,
          withStateSet: true,
        });
        handleTileClick(cleanedFeature, PUBLIC_SOURCE_NAME);
      }
    }
  };

  const onPublicHover = useCallback(
    (e: MapMouseEvent) => {
      const feature = mapRoot?.queryRenderedFeatures(e.point)[0];
      if (feature) {
        if (hoveredPublicFeatureId.current) {
          mapRoot?.setFeatureState(
            {
              source: PUBLIC_SOURCE_NAME,
              id: hoveredPublicFeatureId.current,
            },
            { hover: false }
          );
          hoveredPublicFeatureId.current = null;
        }
        mapRoot?.setFeatureState(
          { source: PUBLIC_SOURCE_NAME, id: feature.id },
          { hover: true }
        );
        hoveredPublicFeatureId.current = String(feature.id);
      }
    },
    [hoveredPublicFeatureId, mapRoot]
  );

  const onTileHover = useCallback(
    (e: MapMouseEvent) => {
      const feature = mapRoot?.queryRenderedFeatures(e.point)[0];
      if (feature) {
        if (hoveredFeatureId.current) {
          mapRoot?.setFeatureState(
            { source: SOURCE_NAME, id: hoveredFeatureId.current },
            { hover: false }
          );
          hoveredFeatureId.current = null;
        }

        mapRoot?.setFeatureState(
          { source: SOURCE_NAME, id: feature.id },
          { hover: true }
        );
        hoveredFeatureId.current = String(feature.id);
      }
    },
    [hoveredFeatureId, mapRoot]
  );

  const onTileBlur = useCallback(() => {
    if (hoveredFeatureId.current) {
      mapRoot?.setFeatureState(
        { source: SOURCE_NAME, id: hoveredFeatureId.current },
        { hover: false }
      );
      hoveredFeatureId.current = null;
    }
  }, [mapRoot]);

  const onPublicTileBlur = useCallback(() => {
    if (hoveredPublicFeatureId.current) {
      mapRoot?.setFeatureState(
        {
          source: PUBLIC_SOURCE_NAME,
          id: hoveredPublicFeatureId.current,
        },
        { hover: false }
      );
      hoveredPublicFeatureId.current = null;
    }
  }, [mapRoot]);

  const handleStyleChange = useCallback(
    (style: keyof typeof MAPBOX_MAP_STYLES) => {
      const newStyle = MAPBOX_MAP_STYLES[style];
      setMapStyle(newStyle);
      resetPolygonData();
      mapRoot?.flyTo(INITIAL_MAP_VIEW_STATE);
      dispatch(resetDrawSliceState());
      dispatch(resetUIState());
    },
    [dispatch, mapRoot, resetPolygonData]
  );

  const handleMouseMove = useCallback(
    (e: MapMouseEvent) => {
      if (isDrawing) {
        dispatch(setDrawLngLat({ ...e.lngLat } as LngLat));
      }

      if (!isDrawing && mapRoot && e.point) {
        const feature = mapRoot.queryRenderedFeatures(e.point)[0];
        if (feature) {
          const sourceName = feature.source;
          if (sourceName === USER_SOURCE_NAME) {
            onUserTileHover(e);
          } else if (sourceName === SOURCE_NAME) {
            onTileHover(e);
          } else if (sourceName === PUBLIC_SOURCE_NAME) {
            onPublicHover(e);
          }
        }
      }
    },
    [dispatch, isDrawing, mapRoot, onPublicHover, onTileHover, onUserTileHover]
  );

  useEffect(() => {
    if (!isDrawing) {
      mapRoot?.on(
        'mouseleave',
        [TILE_LAYERS.FILLS, TILE_LAYERS.OUTLINE],
        onTileBlur
      );
      mapRoot?.on(
        'mouseleave',
        [USER_TILE_LAYERS.FILLS, USER_TILE_LAYERS.OUTLINE],
        onUserTileBlur
      );
      mapRoot?.on(
        'mouseleave',
        [PUBLIC_TILE_LAYERS.FILLS, PUBLIC_TILE_LAYERS.OUTLINE],
        onPublicTileBlur
      );
    } else {
      mapRoot?.off(
        'mouseleave',
        [TILE_LAYERS.FILLS, TILE_LAYERS.OUTLINE],
        onTileBlur
      );
      mapRoot?.off(
        'mouseleave',
        [USER_TILE_LAYERS.FILLS, USER_TILE_LAYERS.OUTLINE],
        onUserTileBlur
      );
      mapRoot?.off(
        'mouseleave',
        [PUBLIC_TILE_LAYERS.FILLS, PUBLIC_TILE_LAYERS.OUTLINE],
        onPublicTileBlur
      );
    }

    return () => {
      mapRoot?.off(
        'mouseleave',
        [TILE_LAYERS.FILLS, TILE_LAYERS.OUTLINE],
        onTileBlur
      );
      mapRoot?.off(
        'mouseleave',
        [USER_TILE_LAYERS.FILLS, USER_TILE_LAYERS.OUTLINE],
        onUserTileBlur
      );
      mapRoot?.off(
        'mouseleave',
        [PUBLIC_TILE_LAYERS.FILLS, PUBLIC_TILE_LAYERS.OUTLINE],
        onPublicTileBlur
      );
    };
  }, [isDrawing, mapRoot, onPublicTileBlur, onTileBlur, onUserTileBlur]);

  const handleOnClick = async (e: MapLayerMouseEvent) => {
    // get clicked feature source name
    const feature = mapRoot?.queryRenderedFeatures(e.point)[0];

    if (feature) {
      const sourceName = feature.source;
      if (sourceName === USER_SOURCE_NAME) {
        await onUserTileClick(e);
      } else if (sourceName === SOURCE_NAME) {
        onTileClick(e);
      } else if (sourceName === PUBLIC_SOURCE_NAME) {
        await onPublicClick(e);
      }
    }
  };

  /**
   * Handling map moving with mouse/touch events
   * @param {ViewStateChangeEvent} e Map view state event change param
   */
  const handleOnMove = (e: ViewStateChangeEvent) => {
    setViewState({ ...e.viewState });
  };

  const handleDrawCreate = useCallback(
    (e: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) => {
      const features = [truncate(e.features[0], { precision: 6 })];
      dispatch(onDrawCreate(features));
    },
    [dispatch]
  );

  const handleDrawUpdate = useCallback(
    (e: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) => {
      const features = [truncate(e.features[0], { precision: 6 })];
      dispatch(onDrawUpdate(features));
    },
    [dispatch]
  );

  /**
   * Helper function for formatting distributed data to map a library-required format
   */
  const prepareRegionsData = useCallback(
    (
      regions:
        | GeoJSON.Feature<GeoJSON.Polygon>[]
        | IRegionResponse[]
        | PublicProject[]
    ) => {
      const newArr: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
        type: 'FeatureCollection',
        features: [],
      };

      regions.forEach((tile) => {
        newArr.features.push({
          type: 'Feature',
          geometry: tile.geometry as GeoJSON.Polygon,
          properties: {
            ...tile.properties,
            // record original geometry (for some reason, mapbox-gl changes it a little)
            originalGeometry: tile.geometry,
          },
        });
      });

      return newArr;
    },
    []
  );

  /**
   * Render of React-Map-GL Source for the BiomassAPI predefined tiles
   */
  const renderPredefinedTiles = useCallback(() => {
    if (tiles && tiles.length > 0) {
      const tilesData = prepareRegionsData(tiles);

      return (
        <Source id={SOURCE_NAME} type="geojson" data={tilesData} generateId>
          <Layer
            type="line"
            id={TILE_LAYERS.OUTLINE}
            source={SOURCE_NAME}
            paint={LinePaint}
            beforeId={beforeIdOutline}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('default') ? 'visible' : 'none',
            }}
          />
          <Layer
            type="fill"
            id={TILE_LAYERS.FILLS}
            beforeId={beforeIdFill}
            source={SOURCE_NAME}
            paint={FillPaint}
            layout={{
              visibility: sources.includes('default') ? 'visible' : 'none',
            }}
          />
        </Source>
      );
    }
  }, [beforeIdFill, beforeIdOutline, prepareRegionsData, sources, tiles]);

  /**
   * Render of React-Map-GL Source for the user saved regions
   */
  const renderUserRegions = useCallback(() => {
    // first check if predefined tiles are already rendered on the map
    if (tiles?.length > 0 && userTiles?.length > 0) {
      const tilesData = prepareRegionsData(userTiles);

      return (
        <Source
          id={USER_SOURCE_NAME}
          type="geojson"
          data={tilesData}
          generateId
        >
          <Layer
            type="line"
            id={USER_TILE_LAYERS.OUTLINE}
            source={USER_SOURCE_NAME}
            paint={UserLinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('user') ? 'visible' : 'none',
            }}
          />
          <Layer
            type="fill"
            id={USER_TILE_LAYERS.FILLS}
            source={USER_SOURCE_NAME}
            paint={UserFillPaint}
            layout={{
              visibility: sources.includes('user') ? 'visible' : 'none',
            }}
          />
        </Source>
      );
    }
  }, [prepareRegionsData, sources, tiles, userTiles]);

  /**
   * Render of React-Map-GL Source for the currently drawn polygon
   * This part of data is rendered on existing tile
   */
  const renderSplitDrawnPolygonOnTile = useCallback(() => {
    if (splitDrawnPolygon?.drawnDataOnTile) {
      return (
        <Source
          id={SPLIT_DRAWN_SOURCE_NAME_UNFILLED}
          type="geojson"
          data={splitDrawnPolygon.drawnDataOnTile}
          generateId
        >
          <Layer
            type="line"
            id={SPLIT_TILE_LAYERS_UNFILLED.OUTLINE}
            source={SPLIT_DRAWN_SOURCE_NAME_UNFILLED}
            paint={DrawInsideTileLinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
          />
          <Layer
            type="fill"
            id={SPLIT_TILE_LAYERS_UNFILLED.FILLS}
            source={SPLIT_DRAWN_SOURCE_NAME_UNFILLED}
            paint={DrawInsideTileFillPaint}
          />
        </Source>
      );
    }
  }, [splitDrawnPolygon?.drawnDataOnTile]);

  /**
   * Render of React-Map-GL Source for the currently drawn polygon
   * This part of data is rendered outside existing tile
   */
  const renderSplitDrawnPolygonOutsideTile = useCallback(() => {
    if (splitDrawnPolygon?.drawnDataOutsideTile) {
      return (
        <Source
          id={SPLIT_DRAWN_SOURCE_NAME_FILELD}
          type="geojson"
          data={splitDrawnPolygon.drawnDataOutsideTile}
          generateId
        >
          <Layer
            type="line"
            id={SPLIT_TILE_LAYERS_FILLED.OUTLINE}
            source={SPLIT_DRAWN_SOURCE_NAME_FILELD}
            paint={DrawOutsideTileLinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
          />
          <Layer
            type="fill"
            id={SPLIT_TILE_LAYERS_FILLED.FILLS}
            source={SPLIT_DRAWN_SOURCE_NAME_FILELD}
            paint={DrawOutsideTileFillPaint}
          />
        </Source>
      );
    }
  }, [splitDrawnPolygon?.drawnDataOutsideTile]);

  /**
   * Render of Public Projects
   */
  const renderPublicProjects = useCallback(() => {
    if (tiles.length > 0 && publicProjects.length > 0) {
      return (
        <Source
          id={PUBLIC_SOURCE_NAME}
          type="geojson"
          data={prepareRegionsData(publicProjects)}
          generateId
        >
          <Layer
            type="line"
            id={PUBLIC_TILE_LAYERS.OUTLINE}
            source={PUBLIC_SOURCE_NAME}
            paint={PublicProjectsLinePaint}
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
              visibility: sources.includes('public') ? 'visible' : 'none',
            }}
          />
          <Layer
            type="fill"
            id={PUBLIC_TILE_LAYERS.FILLS}
            source={PUBLIC_SOURCE_NAME}
            paint={PublicProjectsFillPaint}
            layout={{
              visibility: sources.includes('public') ? 'visible' : 'none',
            }}
          />
        </Source>
      );
    }
  }, [publicProjects, prepareRegionsData, sources, tiles.length]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (event.data.type === 'numberOfPoints') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        dispatch(setNumberOfPoints(+event.data.numberOfPoints + 1));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const toastOptions: ToastOptions = {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: 'hint-draw-first',
      autoClose: false,
    };

    if (isDrawing) {
      if (!numberOfPoints) {
        toast(
          <Hint>{t('Click a point on the map to begin drawing.')}</Hint>,
          toastOptions
        );
      } else if (numberOfPoints === 1) {
        toast.dismiss('hint-draw-first');
      } else if (numberOfPoints === 2) {
        toast(
          <Hint>
            {t(
              'Finish your region by double-clicking or returning to the starting point.'
            )}
          </Hint>,
          { ...toastOptions, toastId: 'hint-draw-second' }
        );
      }
    }
  }, [isDrawing, numberOfPoints, t]);

  return (
    <MapContainer
      className="mapboxgl-map"
      isInteractive={isInteractive}
      shouldShrink={showSidebar}
    >
      <Map
        {...viewState}
        onMove={handleOnMove}
        onClick={!isDrawing ? handleOnClick : undefined}
        onMouseMove={handleMouseMove}
        mapStyle={mapStyle}
        preserveDrawingBuffer
        id="mapRoot"
        data-test-id="map"
        style={{
          width: '100vw',
          height: '100vh',
        }}
        renderWorldCopies={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      >
        {isDrawing ? (
          <DrawControl
            defaultMode="draw_polygon"
            onCreate={handleDrawCreate}
            keybindings={false}
            onUpdate={handleDrawUpdate}
            userProperties={true}
            styles={DrawStyles}
            displayControlsDefault={false}
            onModeChange={onModeChange}
          />
        ) : null}
        {drawnFeatures.length === 0 &&
        isDrawing &&
        !selectedPolygon?.userId &&
        !selectedPolygon?.getInDrawMode ? (
          <DrawInfoComponent />
        ) : null}
        <NavigationControl position="bottom-right" showCompass={false} />
        <StyleChange setStyle={handleStyleChange} currentStyle={mapStyle} />
        {isInteractive ? renderSplitDrawnPolygonOnTile() : null}
        {isInteractive ? renderSplitDrawnPolygonOutsideTile() : null}
        {isInteractive ? renderPredefinedTiles() : null}
        {isInteractive ? renderUserRegions() : null}
        {isInteractive ? renderPublicProjects() : null}
        {children}
      </Map>
    </MapContainer>
  );
};

export default MapComponent;
