import styled from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';

export const Wrapper = styled.header`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  height: 72px;
  gap: 47px;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(3, 8, 24, 0.5), rgba(3, 8, 24, 0.5)),
    radial-gradient(
      50% 19455.81% at 50% 50.69%,
      #1c4e64 33.85%,
      #1d284f 66.67%,
      #182b31 100%
    );
`;

export const NavWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 48px;
  gap: 32px;
  list-style: none;
  margin-bottom: 0;
`;

export const NavigationItem = styled.li<{
  disabled?: boolean;
  showProLabel?: boolean;
}>`
  position: relative;
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  transition: ${({ theme }) => theme.transition};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  a,
  span {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    transition: ${({ theme }) => theme.transition};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);

    a,
    span {
      color: ${({ theme }) => theme.colors.green};
    }
  }
  &:before {
    display: ${({ showProLabel }) => (showProLabel ? 'block' : 'none')};
    content: 'PRO';
    position: absolute;
    top: -5px;
    right: -15px;
    color: ${({ theme }) => theme.colors.blackI};
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.purple};
  }
`;

export const MenuNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-left: auto;
`;

export const NotificationsButton = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  padding: 0;
  margin: 5px 0 0;
  display: flex;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }

  ${IconWrapper} {
    -webkit-mask-size: contain;
    mask-size: contain;
  }
`;

export const NotificationsCounter = styled.p<{ active?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  top: -7px;
  right: -5px;
  transform: translate(0, 20px);
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blackI};
  padding: 3px 7px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1.5px solid ${({ theme }) => theme.colors.blackI};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: ${({ theme }) => theme.transition};
  opacity: 0;

  ${({ active }) =>
    active &&
    `
    opacity: 1;
    transform: translate(0, 9px);
  `};
`;

export const UserPlaceholderAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green};
  display: flex;
  justify-content: center;
  align-items: center;
`;
