import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalType =
  | 'account'
  | 'adminCacheInvalidation'
  | 'adminEditUser'
  | 'adminFindUser'
  | 'apiKey'
  | 'changeRegionsFolder'
  | 'contactUs'
  | 'createFolder'
  | 'createPublicProject'
  | 'deleteAccount'
  | 'deleteDocument'
  | 'deleteFolder'
  | 'deletePublicProject'
  | 'deletePublicProjectPDF'
  | 'deleteRegion'
  | 'deleteRegions'
  | 'editFolder'
  | 'editPublicProject'
  | 'editPublicProjectPDF'
  | 'editRegion'
  | 'editRegionInvalidShape'
  | 'editRegionMissingData'
  | 'editRegionNoCoverage'
  | 'editRegionTooLargeShape'
  | 'joyrideWelcome'
  | 'pdfReport'
  | 'renameDocument'
  | 'saveRegion'
  | 'unsubscribeRegion'
  | 'upgradeAccount'
  | 'uploadShapefile'
  | 'userSettings'
  | 'uploadDocument';

interface ModalState {
  activeModal: ModalType | null;
}

const initialState: ModalState = {
  activeModal: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalType>) => {
      state.activeModal = action.payload;
    },
    closeModal: (state) => {
      state.activeModal = null;
    },
  },
});

export default modalSlice.reducer;
export const { openModal, closeModal } = modalSlice.actions;
