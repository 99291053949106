import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';
import { DraggableWrapper } from './style';

interface Props extends PropsWithChildren {
  id: string;
  disabled?: boolean;
  folderId?: string;
  regionId?: string;
}

const Draggable = ({ children, id, folderId, regionId, disabled }: Props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    disabled,
    data: { folderId, regionId },
  });

  return (
    <DraggableWrapper
      ref={setNodeRef}
      transform={transform as { x: number; y: number }}
      {...listeners}
      {...attributes}
    >
      {children}
    </DraggableWrapper>
  );
};

export default Draggable;
