import { IBiomassRequest, IDeforestationResponse } from '../../types/Geo';

export type CarbonAccountingData = {
  name: string;
  carbon: number;
  time: string;
};

export type CarbonDisplayPerType = 'data' | 'data/area';
export type CarbonDataType =
  | 'sequestered-carbon'
  | 'above-ground-biomass'
  | 'change-in-biomass'
  | 'carbon-stored'
  | 'total-living-biomass'
  | 'below-ground-biomass';
export type ForestCoverData =
  | {
      name: string;
      time: string;
      forest?: string | number;
      deforestation: string | number;
    }
  | {
      name: string;
      time: string;
      deforestation: number;
      forest?: string | number;
    };

export enum ForestTemporalType {
  LINEAR = 'linear',
  YoY = 'year-over-year',
}

export enum ForestDataType {
  PERCENTAGE = 'percentage',
  AREA = 'area',
}

export interface IPolygonContext {
  // selected polygon data
  calculatedArea: number;
  resetPolygonData: (omitResetDraw?: boolean, withDelay?: boolean) => void;
  getPolygonData: () => void;

  // forestCover
  getPolygonForestCoverData: (
    body: Omit<IBiomassRequest, 'type'>,
    temporalType?: ForestTemporalType,
    dataType?: ForestDataType,
    shouldReturn?: boolean
  ) => Promise<ForestCoverData[] | null>;

  //deforestation
  deforestationData: IDeforestationResponse | null;

  // carbon accounting
  getPolygonCarbonAccountingData: (
    body: Omit<IBiomassRequest, 'type'>,
    dataType: CarbonDataType,
    displayPer: CarbonDisplayPerType,
    shouldReturn?: boolean
  ) => Promise<CarbonAccountingData[] | null>;
}

export const defaultState: IPolygonContext = {
  // selected polygon data
  calculatedArea: 0,
  resetPolygonData: () => null,
  getPolygonData: () => null,

  // forestCover
  getPolygonForestCoverData: () => Promise.resolve(null),

  // deforestation
  deforestationData: null,

  // carbon accounting
  getPolygonCarbonAccountingData: () => Promise.resolve(null),
};
